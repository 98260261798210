import React, { CSSProperties, MutableRefObject, useRef, MouseEvent } from "react";
import classNames from "classnames";

const colorMap = require("./ButtonColorMap.json");
const styles = require("./Button.module.scss");

export interface ButtonProps {
	className?: string;
	children?: React.ReactNode;
	size?: "compact" | "standard" | "large";
	color?: "primary" | "secondary" | "green";
	customColorMap?: {
		background?: string;
		border?: string;
		text?: string;
		events?: { [key: string]: any };
	};
	theme?: "solid" | "soft";
	tone?: "dark" | "medium" | "light";
	loading?: boolean;
	locked?: boolean;
	fullWidth?: boolean;
	style?: CSSProperties | undefined;
	forwardRef?: MutableRefObject<HTMLButtonElement | null>;
	onClick: (event: React.MouseEvent) => void;
}

// const acceptableColorOptions = ["primary", "secondary", "green"];
// const acceptableThemeOptions = ["solid", "soft"];
// const acceptableToneOptions = ["dark", "medium", "light"];

export default function Button({
								   children,
								   className,
								   size = "standard",
								   color = "primary",
								   theme = "soft",
								   tone = "medium",
								   customColorMap,
								   loading = false,
								   locked = false,
								   fullWidth = false,
								   forwardRef,
								   onClick,
								   style,
							   }: ButtonProps) {
	const buttonElement: MutableRefObject<HTMLButtonElement | null> =
		forwardRef === undefined ? useRef(null) : forwardRef;

	let styleForPesudo: HTMLStyleElement | null;

	// if (!acceptableColorOptions.includes(color)) color = 'primary';
	// if (!acceptableThemeOptions.includes(theme)) theme = 'soft';
	// if (!acceptableThemeOptions.includes(tone)) tone = 'medium';

	const buttonClasses = classNames([
		styles.Button,
		styles.Clickable,
		size && styles[`size_${size}`],
		locked && styles.Locked,
		fullWidth && styles.FullWidth,
		className,
	]);

	// @ts-ignore
	let { events, ...colorStyles } = colorMap[`${color}.${theme}.${tone}`];

	let buttonStyles = {
		...colorStyles,
		...style,
	};

	if (customColorMap) {
		if (customColorMap.background) {
			buttonStyles.background = customColorMap.background;
		}
		if (customColorMap.border) {
			buttonStyles.border = customColorMap.border;
		}
		if (customColorMap.text) {
			buttonStyles.color = customColorMap.text;
		}
	}

	function handleClick(event: MouseEvent) {
		if (locked) {
			return;
		}

		onClick(event);
	}

	function handleHoverStart() {
		const event = events.hover;

		if (!event) {
			return;
		}

		Object.keys(event).forEach((propertyName) => {
			if (buttonElement.current === null) return;

			// @ts-ignore
			buttonElement.current.style[propertyName] = event[propertyName];
		});
	}

	function handleHoverEnd() {
		Object.keys(buttonStyles).forEach((propertyName) => {
			if (buttonElement.current === null) return;

			// @ts-ignore
			buttonElement.current.style[propertyName] = buttonStyles[propertyName];
		});
	}

	const button = (
		<button
			ref={buttonElement}
			className={buttonClasses}
			style={buttonStyles}
			onClick={handleClick}
			onMouseEnter={handleHoverStart}
			onMouseLeave={handleHoverEnd}
		>
			{children}
		</button>
	);

	if (fullWidth) {
		return <div>{button}</div>;
	}

	return <div style={{ display: "inline-block" }}>{button}</div>;
}
