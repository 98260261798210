import React from "react";
import {RouteComponentProps} from "@reach/router";
const styles = require('./Header.module.scss');

interface Props extends RouteComponentProps {
    toggleMobileMenu: () => void;
}

const MobileMenuIcon: React.FunctionComponent<Props> = ({toggleMobileMenu}) => {

    return (
        <div className={styles.MobileMenuIcon} onClick={toggleMobileMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20">
                <g transform="translate(-264 -481)">
                    <g transform="translate(264 481)" fill="#265dde" stroke="#265dde" strokeWidth="1">
                        <rect width="30" height="4" rx="2" stroke="none"/>
                        <rect x="0.5" y="0.5" width="29" height="3" rx="1.5" fill="none"/>
                    </g>
                    <g transform="translate(264 489)" fill="#265dde" stroke="#265dde" strokeWidth="1">
                        <rect width="30" height="4" rx="2" stroke="none"/>
                        <rect x="0.5" y="0.5" width="29" height="3" rx="1.5" fill="none"/>
                    </g>
                    <g transform="translate(264 497)" fill="#265dde" stroke="#265dde" strokeWidth="1">
                        <rect width="30" height="4" rx="2" stroke="none"/>
                        <rect x="0.5" y="0.5" width="29" height="3" rx="1.5" fill="none"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default MobileMenuIcon;