exports.components = {
  "component---src-components-layouts-answering-services-answering-services-tsx": () => import("./../../../src/components/Layouts/AnsweringServices/AnsweringServices.tsx" /* webpackChunkName: "component---src-components-layouts-answering-services-answering-services-tsx" */),
  "component---src-components-layouts-sales-intro-tsx": () => import("./../../../src/components/Layouts/Sales/Intro.tsx" /* webpackChunkName: "component---src-components-layouts-sales-intro-tsx" */),
  "component---src-components-layouts-video-course-course-tsx": () => import("./../../../src/components/Layouts/VideoCourse/Course.tsx" /* webpackChunkName: "component---src-components-layouts-video-course-course-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-getting-started-index-tsx": () => import("./../../../src/pages/getting-started/index.tsx" /* webpackChunkName: "component---src-pages-getting-started-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-gdpr-tsx": () => import("./../../../src/pages/legal/gdpr.tsx" /* webpackChunkName: "component---src-pages-legal-gdpr-tsx" */),
  "component---src-pages-legal-hipaa-tsx": () => import("./../../../src/pages/legal/hipaa.tsx" /* webpackChunkName: "component---src-pages-legal-hipaa-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-providers-tsx": () => import("./../../../src/pages/legal/providers.tsx" /* webpackChunkName: "component---src-pages-legal-providers-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-platform-automations-tsx": () => import("./../../../src/pages/platform/automations.tsx" /* webpackChunkName: "component---src-pages-platform-automations-tsx" */),
  "component---src-pages-platform-jobs-tsx": () => import("./../../../src/pages/platform/jobs.tsx" /* webpackChunkName: "component---src-pages-platform-jobs-tsx" */),
  "component---src-pages-platform-workflows-tsx": () => import("./../../../src/pages/platform/workflows.tsx" /* webpackChunkName: "component---src-pages-platform-workflows-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-schedule-a-call-tsx": () => import("./../../../src/pages/schedule-a-call.tsx" /* webpackChunkName: "component---src-pages-schedule-a-call-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-submit-issue-tsx": () => import("./../../../src/pages/submit-issue.tsx" /* webpackChunkName: "component---src-pages-submit-issue-tsx" */)
}

