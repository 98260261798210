import React, {MutableRefObject} from "react"
import Logo from "../Logo";
import {Link} from "gatsby";
import Button from "../Button/Button";

const styles = require("./MobileMenu.module.scss");

interface Props {
	showMobileMenu: boolean;
	currentPageId: string;
	forwardRef: MutableRefObject<HTMLDivElement | null>;
	onHide: () => void;
}

const MobileMenu: React.FunctionComponent<Props> = ({ showMobileMenu, currentPageId, forwardRef, onHide }) => {
	function onClick() {
		onHide();
	}

	return (
		<div className={styles.MobileMenu} ref={forwardRef}>
			<div className={styles.Head}>
				<Logo />
				<svg
					className={styles.CloseBtn}
					onClick={onHide}
					xmlns="http://www.w3.org/2000/svg"
					width="40"
					height="40"
					viewBox="0 0 40 40"
				>
					<g transform="translate(-676 -93)">
						<circle cx="20" cy="20" r="20" transform="translate(676 93)" fill="#e9ebf2" />
						<g transform="translate(685.987 102.333)">
							<path
								d="M665.155,748.385a1.64,1.64,0,0,1-1.107.459,1.564,1.564,0,0,1-1.107-.459l-7.454-7.475-7.538,7.538a1.482,1.482,0,0,1-1.107.459,1.549,1.549,0,0,1-1.107-.459,1.576,1.576,0,0,1,0-2.213l7.538-7.538-7.266-7.266a1.538,1.538,0,0,1,0-2.213,1.575,1.575,0,0,1,2.213,0l7.266,7.266,7.183-7.183a1.575,1.575,0,0,1,2.213,0,1.539,1.539,0,0,1,0,2.213L657.7,738.7l7.454,7.454A1.6,1.6,0,0,1,665.155,748.385Z"
								transform="translate(-645.283 -728.763)"
								fill="#a2a9ba"
							/>
						</g>
					</g>
				</svg>
			</div>
			<nav className={styles.Nav}>
				<div>
					<div className={styles.Link}>
						<Link onClick={onClick} to={"/"}>Home</Link>
					</div>
					<div className={styles.Link}>
						<span>Platform</span>
						<div className={styles.SubMenu}>
							<div className={styles.Link}>
								<Link onClick={onClick} to={"/platform/workflows"}>Workflows</Link>
							</div>
							<div className={styles.Link}>
								<Link onClick={onClick} to={"/platform/jobs"}>Jobs</Link>
							</div>
							<div className={styles.Link}>
								<Link onClick={onClick} to={"/platform/automations"}>Automations</Link>
							</div>
						</div>
					</div>
					<div className={styles.Link}>
						<Link onClick={onClick} to={"/pricing"}>Pricing</Link>
					</div>
					{/*<div className={styles.Link}>*/}
					{/*	<Link to={"/about"}>About</Link>*/}
					{/*</div>*/}
					<div className={styles.Link}>
						<Link onClick={onClick} to={"/contact-us"}>Contact Us</Link>
					</div>
				</div>
				<div>
					<div className={styles.Link}>
						<a target="_blank" href="https://app.prestavi.com/login">Log in</a>
					</div>
					<div>
						<Button onClick={() => window.location.href = "https://app.prestavi.com/signup"} theme="solid" tone="medium" size="compact">Start Trial</Button>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default MobileMenu;
