import React from "react";
import classNames from "classnames";

const styles = require("./SubMenu.module.scss");

interface Props {
	show: boolean;
	onClose: () => void;
}

const SubMenu: React.FunctionComponent<Props> = ({show, onClose, children}) => {
	return (
		<div>
			<div className={classNames([styles.SubMenu, show && styles.Show])}>
				{children}
			</div>
			{show && (
				<div className={styles.Backdrop} onClick={onClose}></div>
			)}
		</div>
	);
};

export default SubMenu;
