import React from "react"
import { Link } from "gatsby"
import {Logo30} from "./Logo/Logos";


const Logo: React.FunctionComponent = () => {
  return (
    <Link to="/">
      <div className="">
        {Logo30}
      </div>
    </Link>
  )
}

export default Logo
