import React, {MutableRefObject} from "react"

import MobileMenuIcon from "./MobileMenuIcon"
import Logo from "../Logo";
import {Link} from "gatsby";
import Button from "../Button/Button";

const styles = require("./Header.module.scss");

interface Props {
	showMobileMenu: boolean;
	mobileMenuRef: MutableRefObject<HTMLDivElement | null>;
	toggleMobileMenu: () => void;
	pageId: string;
	onClickNavItemWithSubMenu: (menuId: string) => void;
	showSubMenu: string|null;
}

export function animateMobileMenuVisibility(showingMobileMenu: boolean, mobileMenuRef: MutableRefObject<HTMLDivElement | null>): MutableRefObject<HTMLDivElement | null>  {
	if (!mobileMenuRef.current) return mobileMenuRef;

	if (showingMobileMenu) {
		mobileMenuRef.current.style.transform = "translateX(-500px)";
	} else {
		mobileMenuRef.current.style.transform = "translateX(0px)";
	}

	return mobileMenuRef;
}


const Header: React.FunctionComponent<Props> = ({mobileMenuRef, showMobileMenu, showSubMenu, toggleMobileMenu, pageId, onClickNavItemWithSubMenu}) => {
	let backgroundColor = 'transparent';

	if (pageId === '/' && showSubMenu === null) {
		// backgroundColor = 'var(--color-primary-100)'
	}

	return (
		<header className={styles.Header} style={{backgroundColor}}>
			<div className={styles.Logo}>
				<Logo />
			</div>
			<nav className={styles.Nav}>
				<div>
					<div className={styles.Link} onClick={() => onClickNavItemWithSubMenu("platform")}>
						<span>
							Features
							&nbsp;
							<svg xmlns="http://www.w3.org/2000/svg" width="9.016" height="5.42" viewBox="0 0 9.016 5.42">
							  <g transform="translate(0 0)">
								<path d="M0,.624A.624.624,0,0,1,1.068.186L4.709,3.881,7.948.211a.624.624,0,0,1,.934.826L5.2,5.209a.623.623,0,0,1-.88.055l-.032-.03L.178,1.062A.623.623,0,0,1,0,.624" transform="translate(0 0)" fill="#98a0b3"/>
							  </g>
							</svg>
						</span>
					</div>
					<div className={styles.Link}>
						<Link to={"/pricing"}>Pricing</Link>
					</div>
					{/*<div className={styles.Link}>*/}
					{/*	<Link to={"/about"}>About</Link>*/}
					{/*</div>*/}
					<div className={styles.Link}>
						<Link to={"/contact-us"}>Contact Us</Link>
					</div>
					{/*<div className={styles.Link} onClick={() => onClickNavItemWithSubMenu("resources")}>*/}
					{/*	<span>*/}
					{/*		Resources*/}
					{/*		&nbsp;*/}
					{/*		<svg xmlns="http://www.w3.org/2000/svg" width="9.016" height="5.42" viewBox="0 0 9.016 5.42">*/}
					{/*		  <g transform="translate(0 0)">*/}
					{/*			<path d="M0,.624A.624.624,0,0,1,1.068.186L4.709,3.881,7.948.211a.624.624,0,0,1,.934.826L5.2,5.209a.623.623,0,0,1-.88.055l-.032-.03L.178,1.062A.623.623,0,0,1,0,.624" transform="translate(0 0)" fill="#98a0b3"/>*/}
					{/*		  </g>*/}
					{/*		</svg>*/}
					{/*	</span>*/}
					{/*</div>*/}
				</div>
				<div>
					<div className={styles.Link}>
						<a href="https://app.prestavi.com/login">Log in</a>
					</div>
					{/*<div className={styles.SignupBtn}>*/}
					{/*	<Button onClick={() => window.location.href = "https://app.prestavi.com/signup"} theme="solid" tone="medium" color="primary" size="compact">Start Trial</Button>*/}
					{/*</div>*/}
					<MobileMenuIcon
						toggleMobileMenu={() => {
							mobileMenuRef = animateMobileMenuVisibility(showMobileMenu, mobileMenuRef);
							toggleMobileMenu();
						}}
					/>
				</div>
			</nav>
		</header>
	);
};

export default Header;
