import React, {MutableRefObject, ReactChildren, useEffect, useRef, useState} from "react"
import MobileMenu from "../MobileMenu/MobileMenu"
import Header from "../Header/Header";
import {calculatePageId} from "../../utils/utils";
import SubMenu from "../Header/SubMenu/SubMenu";
import PlatformSubMenu from "../Header/PlatformSubMenu";
import ResourcesSubMenu from "../Header/ResourcesSubMenu";
import {usePrevious} from "../../utils/hooks/usePrevious";

interface Props {
	children: ReactChildren
	pageContext: {
		[key: string]: any
	}
	location: {
		pathname: string
	}
}

export function animateMobileMenuVisibility(showingMobileMenu: boolean, mobileMenuRef: MutableRefObject<HTMLDivElement | null>): MutableRefObject<HTMLDivElement | null>  {
	if (!mobileMenuRef.current) return mobileMenuRef;

	if (showingMobileMenu) {
		mobileMenuRef.current.style.transform = "translateX(-500px)";
	} else {
		mobileMenuRef.current.style.transform = "translateX(0px)";
	}

	return mobileMenuRef;
}

const Main: React.FunctionComponent<Props> = ({children, pageContext, location}) => {
	const [showingMobileMenu, setShowingMobileMenu] = useState(false);
	const [showSubMenu, setShowSubMenu] = useState<null|string>(null);
	let mobileMenuRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

	const currentPageId = calculatePageId(pageContext.id, location.pathname);
	const prevPageId = usePrevious(currentPageId);

	useEffect(() => {
		if (currentPageId !== prevPageId) {
			setShowSubMenu(null);
		}
	}, [currentPageId])

	return (
		<div>
			<div>
				<div style={{backgroundColor: "#F8DEA1", padding: "1px 16px" }}>
					<h4 style={{color: "#000"}}>Prestavi was hibernated as of Sept 1, 2023. Thank you to our customers that came with us along the journey! –<a href="https://www.linkedin.com/in/mitch-glenn/">Mitch Glenn, Founder & Creator</a></h4>
				</div>
				<Header
					pageId={currentPageId}
					showMobileMenu={showingMobileMenu}
					showSubMenu={showSubMenu}
					onClickNavItemWithSubMenu={(menuId: string) => {
						if (showSubMenu === menuId) {
							setShowSubMenu(null);
							return;
						}

						setShowSubMenu(menuId)
					}}
					mobileMenuRef={mobileMenuRef}
					toggleMobileMenu={() => setShowingMobileMenu(!showingMobileMenu)}
				/>
				<PlatformSubMenu show={showSubMenu === "platform"} onClose={() => setShowSubMenu(null)} />
				{/*<ResourcesSubMenu show={showSubMenu === "resources"} onClose={() => setShowSubMenu(null)} />*/}

				{children}
			</div>
			<MobileMenu
				showMobileMenu={showingMobileMenu}
				currentPageId={currentPageId}
				onHide={() => {
					mobileMenuRef = animateMobileMenuVisibility(showingMobileMenu, mobileMenuRef);
					setShowingMobileMenu(!showingMobileMenu);
				}}
				forwardRef={mobileMenuRef}
			/>

			{showingMobileMenu && (
				<div onClick={() => {
					mobileMenuRef = animateMobileMenuVisibility(showingMobileMenu, mobileMenuRef);
					setShowingMobileMenu(!showingMobileMenu);
				}}></div>
			)}
		</div>
	);
};

export default Main;