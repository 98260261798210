// extracted by mini-css-extract-plugin
export var Backdrop = "SubMenu-module--Backdrop--ov6Td";
export var Content = "SubMenu-module--Content--oSk8n";
export var ItemsGrid = "SubMenu-module--ItemsGrid--haEsb";
export var Links = "SubMenu-module--Links--IzqF+";
export var Main = "SubMenu-module--Main--+qUxO";
export var PlatformItem = "SubMenu-module--PlatformItem--Z0bMj";
export var Show = "SubMenu-module--Show--+W0tb";
export var Side = "SubMenu-module--Side--DcNqa";
export var SubMenu = "SubMenu-module--SubMenu--qfSSx";
export var VideoBtn = "SubMenu-module--VideoBtn--LmlvJ";
export var fadeIn = "SubMenu-module--fadeIn--jR0Iy";