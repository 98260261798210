import React from 'react';

import './src/styles/index.css';
import Helmet from "react-helmet"
import Main from "./src/components/Layouts/Main";

export const wrapPageElement = ({ element, props }) => {
    return (
        <Main {...props}>
          <Helmet>
              <title>Prestavi • Workflow Management Platform</title>
              <meta name="description" content="Prestavi is a workflow platform that makes it easy for people inside and outside of a business to collaborate together on a structured process."/>
          </Helmet>
          {element}
      </Main>
    )
}
