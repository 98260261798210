import React from "react";
import SubMenu from "./SubMenu/SubMenu";

const styles = require("./SubMenu/SubMenu.module.scss");

interface Props {
    show: boolean;
    onClose: () => void;
}

const PlatformSubMenu: React.FunctionComponent<Props> = ({show, onClose}) => {
	return (
		<div className={styles.PlatformSubMenu}>
			<SubMenu show={show} onClose={onClose}>
				<div className={styles.Main}>
					<h5>Platform</h5>
					<div className={styles.ItemsGrid}>
						<a href="/platform/workflows">
							<div className={styles.PlatformItem}>
								<img src="https://cdn.prestavi.com/website/images/icon-workflows.svg" alt="Workflows Icon"/>
								<div>
									<h3>Workflows</h3>
									<span>The new backbone of your business.</span>
								</div>
							</div>
						</a>

						<a href="/platform/jobs">
							<div className={styles.PlatformItem}>
								<img src="https://cdn.prestavi.com/website/images/icons-jobs.svg" alt="Workflows Icon"/>
								<div>
									<h3>Jobs</h3>
									<span>Bring your workflows to life.</span>
								</div>
							</div>
						</a>

						<a href="/platform/automations">
							<div className={styles.PlatformItem}>
								<img src="https://cdn.prestavi.com/website/images/icons-automations.svg" alt="Workflows Icon"/>
								<div>
									<h3>Automations</h3>
									<span>Automate tasks to save time and effort.</span>
								</div>
							</div>
						</a>

					</div>
				</div>
				<div className={styles.Side}>
					<div className={styles.Content}>
						<h5>Get Started</h5>
						<div className={styles.Links}>
							<a href="/getting-started">Getting Started Video Series</a>
							{/*<a href="/demo">Schedule a Demo</a>*/}
						</div>

						{/*<div className={styles.VideoBtn} onClick={() => window.open("https://www.youtube.com/watch?v=XxY_GSuv2Ds")}>*/}
						{/*	<div>*/}
						{/*		<svg xmlns="http://www.w3.org/2000/svg" width="40.633" height="23.09" viewBox="0 0 40.633 23.09">*/}
						{/*			<g transform="translate(0 0)">*/}
						{/*				<path d="M36.9,23.09H3.735A3.735,3.735,0,0,1,0,19.355V3.735A3.735,3.735,0,0,1,3.735,0H36.9a3.735,3.735,0,0,1,3.735,3.735V19.355A3.735,3.735,0,0,1,36.9,23.09" transform="translate(0 0)" fill="#5583ed"/>*/}
						{/*				<path d="M30.989,12.516,21.854,7.242a.544.544,0,0,0-.817.471V18.262a.545.545,0,0,0,.817.471l9.135-5.274a.545.545,0,0,0,0-.943" transform="translate(-4.235 -1.443)" fill="#fff"/>*/}
						{/*			</g>*/}
						{/*		</svg>*/}
						{/*		<p>Watch Overview</p>*/}
						{/*	</div>*/}
						{/*	<svg xmlns="http://www.w3.org/2000/svg" width="26.945" height="17.92" viewBox="0 0 26.945 17.92">*/}
						{/*		<g transform="translate(26.945 17.92) rotate(180)">*/}
						{/*			<path d="M25.6,10.175H1.344A1.29,1.29,0,0,1,0,8.946a1.291,1.291,0,0,1,1.344-1.23H25.6a1.29,1.29,0,0,1,1.343,1.23A1.289,1.289,0,0,1,25.6,10.175" fill="#caced9"/>*/}
						{/*			<path d="M12.3,15.75a1.161,1.161,0,0,1,.17,1.73,1.441,1.441,0,0,1-1.89.16L.545,9.93a.873.873,0,0,1-.23-.2.392.392,0,0,1-.08-.1.864.864,0,0,1-.1-.16.076.076,0,0,1-.02-.04.492.492,0,0,1-.06-.17,1.057,1.057,0,0,1,.09-.85.6.6,0,0,1,.09-.15c.02-.03.05-.06.07-.09A.976.976,0,0,1,.485,8l.06-.04L10.575.29a1.424,1.424,0,0,1,1.89.15,1.153,1.153,0,0,1-.17,1.73L3.435,8.95Z" fill="#a2a9ba"/>*/}
						{/*		</g>*/}
						{/*	</svg>*/}

						{/*</div>*/}
					</div>
				</div>
			</SubMenu>
		</div>
	);
};

export default PlatformSubMenu;
